const contactFormLogics = () => {
    $("#contact-form").submit(function (e) {
        e.preventDefault();
        var name = $("#contact-form #name").val(),
            phone = $("#contact-form #tel").val(),
            email = $("#contact-form #email").val(),
            message = $("#contact-form #message").val();

        if (name !== "" && phone !== "" && email !== "" && message !== "") {
            $.ajax({
                type: "POST",
                url: "https://api.appostol.pl/landing/contact",
                data: { name, phone, email, message },
                success: function () {
                    $("#contact-form").css("display", "none");
                    $(".form-success").fadeIn();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert("Coś poszło nie tak, spróbuj później.");
                },
            });
        } else {
            if (phone === "") {
                $("#contact-form #tel").parent().addClass("error");
            }
            if (email === "") {
                $("#contact-form #email").parent().addClass("error");
            }
            if (name === "") {
                $("#contact-form #name").parent().addClass("error");
            }
            if (message === "") {
                $("#contact-form #message").parent().addClass("error");
            }
        }
    });
};

export { contactFormLogics };
