const scrollMagic = () => {
    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top + $(window).height() / 2;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    if ($("body").hasClass("funkcje")) {
        $(window).on("resize scroll", function () {
            if ($("#ministrant").isInViewport()) {
                $("body").addClass("bg-change");
                $(".tabs a").removeClass("active");
                $(".scrollToMinistrant").addClass("active");
            } else {
                $("body").removeClass("bg-change");
                $(".tabs a").removeClass("active");
                $(".scrolltoOpiekun").addClass("active");
            }
        });

        var elementPosition = $(".tabs").offset();

        $(window).scroll(function () {
            if ($(window).scrollTop() > elementPosition.top) {
                $(".tabs").addClass("sticky");
            } else {
                $(".tabs").removeClass("sticky");
            }
        });
    }
};

export { scrollMagic };
