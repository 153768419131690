const umowFormLogics = () => {
    $("#umow-form .role").change(function (e) {
        if (e.target.value === "Ministrant" || e.target.value === 'Lektor') {
            $(".hide-if-ministrant").fadeOut(100);
            $(".info-bar").delay(100).fadeIn(100);
        } else {
            $(".info-bar").fadeOut(100);
            $(".hide-if-ministrant").delay(100).fadeIn(100);
        }
    });

    $("#umow-form .source").change(function (e) {
        if (e.target.value === "inna") {
            $(".other").fadeIn(100);
        } else {
            $(".other").fadeOut(100);
        }
    });

    $("#umow-form .next-step").click(function () {
        var name = $("#umow-form #name").val(),
            role = $("#umow-form .role").val(),
            diocese = $("#umow-form .diocese").val(),
            parish = $("#umow-form #parish").val();

        if (name !== "" && role !== "" && diocese !== "" && parish !== "") {
            $("#umow-form .step-1").addClass("hidden");
            $("#umow-form .step-2").addClass("visible");
        } else {
            if (name === "") {
                $("#umow-form #name").parent().addClass("error");
            }
            if (role === "0") {
                $("#umow-form .role").parent().addClass("error");
            }
            if (diocese === "0") {
                $("#umow-form .diocese").parent().addClass("error");
            }
            if (parish === "") {
                $("#umow-form #parish").parent().addClass("error");
            }
        }
    });

    $("#umow-form").submit(function (e) {
        e.preventDefault();
        var name = $("#umow-form #name").val(),
            role = $("#umow-form .role").val(),
            diocese = $("#umow-form #diocese").val(),
            parish = $("#umow-form #parish").val(),
            phone = $("#umow-form #tel").val(),
            email = $("#umow-form #email").val(),
            source = $("#umow-form #source").val(),
            other = $("#umow-form #other").val();

        if (phone !== "" && email !== "") {
            $.ajax({
                type: "POST",
                url: "https://api.appostol.pl/landing/presentation",
                data: {
                    name,
                    role,
                    diocese,
                    parish,
                    phone,
                    email,
                    source,
                    other,
                },
                success: function () {
                    $("#umow-form").css("display", "none");
                    $(".form-success").fadeIn();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert("Coś poszło nie tak, spróbuj później.");
                },
            });
        } else {
            if (phone === "") {
                $("#umow-form #tel").parent().addClass("error");
            }
            if (email === "") {
                $("#umow-form #email").parent().addClass("error");
            }
        }
    });
};

export { umowFormLogics };
