const menuMobile = () => {
    $(".hamburger").click(function (e) {
        var $this = $(this);
        e.preventDefault();
        $this.toggleClass("is-active");
        $(".menu--mobile").toggleClass("is-active");
    });
};

export { menuMobile };
