require("es6-promise").polyfill();

import { popups } from "./modules/popup";
import { carousel } from "./modules/carousel";
import { menuMobile } from "./modules/menu";
import { inputIsFilled } from "./modules/input";
import { umowFormLogics } from "./modules/umow-form";
import { contactFormLogics } from "./modules/contact-form";
import { scrollMagic } from "./modules/scrollMagic";
import { scrollTo } from "./modules/scrollTo";

$(document).ready(function () {
    const Site = {
        init: function () {
            carousel();
            popups();
            menuMobile();
            inputIsFilled();
            umowFormLogics();
            contactFormLogics();
            scrollMagic();
            scrollTo();
        },
    };
    Site.init();
});
