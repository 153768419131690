const inputIsFilled = () => {
    $("input[type=text], input[type=email], textarea").keyup(function (e) {
        var $this = $(this);
        if (e.target.value !== "") {
            $this.addClass("filled");
        } else {
            $this.removeClass("filled");
        }
    });

    $("input[type=text], input[type=email], textarea").blur(function (e) {
        var inputs = "input[type=text] input[type=email], textarea";
        if ($(inputs).value === "") {
            $(inputs).removeClass("filled");
        }
        $(this).parent().removeClass("error");
    });

    $("input[type=date]").change(function (e) {
        var $this = $(this);
        if (e.target.value !== "") {
            $this.addClass("filled");
        } else {
            $this.removeClass("filled");
        }
        $this.parent().removeClass("error");
    });

    $("form select").change(function (e) {
        var $this = $(this);
        if (e.target.value !== "0") {
            $this.parent().find("label").addClass("selected");
        } else {
            $this.parent().find("label").removeClass("selected");
        }
        $this.parent().removeClass("error");
    });
};

export { inputIsFilled };
