const PopupsInit = () => {
    $(".popup-youtube").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
    });
    $(".image-popup").magnificPopup({
        type: "image",
    });
};

const popups = () => {
    $(document).ready(function ($) {
        PopupsInit();
    });
};

export { popups };
