const slickSlider = () => {
    $(".carousel").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        dots: true,
        arrows: false,
    });
    $(".carousel-quotes").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        dots: false,
        arrows: false,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        }, ],
    });
    $(".carousel-articles").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        dots: false,
        arrows: false,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        }, ],
    });
    $(".carousel-functions").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        dots: true,
        arrows: false,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: false,
            },
        }, ],
    });
};
const carousel = () => {
    slickSlider();
};

export { carousel };