const scrollTo = () => {
    $(".scrolltoOpiekun").click(function () {
        $(".tabs a").removeClass("active");
        $(this).addClass("active");
        $("html, body").animate(
            { scrollTop: $("#opiekun-wspolnoty").offset().top - 150 },
            "slow"
        );
    });
    $(".scrollToMinistrant").click(function () {
        $(".tabs a").removeClass("active");
        $(this).addClass("active");
        $("html, body").animate(
            { scrollTop: $("#ministrant").offset().top - 150 },
            "slow"
        );
    });
};

export { scrollTo };
